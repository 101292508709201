<template>
  <div class="bg-white is-relative c-w-100 border-bottom-3-grey-13">
    <div
      v-if="title"
      class="sticky-left flex-center-vertical border-bottom-1-grey-13"
      :style="{
        height: `${S.HEIGHT_STICKY_TITLE_SECTION}px`,
        width: '100%',
        minWidth: `${S.WIDTH_TABLE}px`,
        top: `${S.HEIGHT_COLUMN_HEADER}px`,
        background,
        zIndex: zIndex + 1
      }"
    >
      <p class="averta-black label-16 title-section">{{ title }}</p>
    </div>
    <div class="is-flex is-relative">
      <!-- SIDE TITLE -->
      <div
        class="sticky-left bg-white"
        :style="{
          width: S.WIDTH_LAYOUT_LEFT,
          zIndex
        }"
      >
          <!-- minWidth: `${S.WIDTH_STICKY_TITLE_LEFT}px`, -->
        <div
          v-for="(item, index) in dataItem"
          :key="index"
          class="column-body left-body p-0"
          :class="{'shadow-item-section': !isDisabled(item)}"
          :style="{
            width: '100%',
            height: `${S.HEIGHT_INIT_COLUMN + 10}.1px`,
            zIndex
          }"
        >
          <div
            v-if="item.name || (item.selected && item.selected.length > 0) || item.fee_type"
            class="is-clickable flex-shrink-0 ml-2 mt-1"
            @click="handleEditNote(item, index)"
          >
            <img :src="require('@/assets/icons/note-red-used.svg')" alt="">
          </div>

          <InputAutocompleteMaterials
            v-if="isMaterial"
            :value="item"
            :hideBorder="true"
            :disabled="isDisabled(item)"
            :idAutocomplete="`${index}-${code}`"
            @selectedUnit="handleSelectMaterial($event, item)"
          />

          <div v-else-if="isWorkerFees" class="c-w-100">
            <!-- <div class="mx-3 flex-center-vertical is-justify-content-space-between">
              <p v-if="item.fee_type" class="averta-bold">{{ item.fee_type == 1 ? 'Upah Tukang' : 'Upah Kenek' }}</p>
              <p v-else class="averta-reguler-italic color-red">Pilih Upah Kerja</p>
              <a class="card-header-icon p-0" v-if="!isDisabled(item)">
                <b-icon
                  :icon="openModal ? 'menu-up' : 'menu-down'">
                </b-icon>
              </a>
            </div> -->

            <InputAutocompleteUpah
              :value="{ name: item.name, fee_type: item.fee_type }"
              :hideBorder="true"
              :unsetMaxWidth="true"
              :bold="true"
              :disabled="isDisabled(item)"
              :idAutocomplete="`${index}-${code}`"
              @selected="handleSelectUpah($event, item)"
            />
          </div>

          <div v-else class="mx-3 c-w-100 max-c-h-100 overflow-auto">
            <p v-if="isDisabled(item)">
              <span v-if="item.name" class="averta-bold">{{item.name}}</span>
              <span v-else class="averta-reguler-italic color-red">
                Pilih {{
                  code === 'work_tools' ? 'Alat Kerja' :
                  code === 'auxiliary_tools' ? 'Alat Bantu' : 'Pilih'
                }}
              </span>
            </p>
            <div v-else class="c-w-100">
              <input
                type="text"
                class="input"
                :placeholder="`Masukan ${code === 'work_tools' ? 'Alat Kerja' : code === 'auxiliary_tools' ? 'Alat Bantu' : ''}`"
                v-model="item.name"
              />
            </div>
          </div>
        </div>
      </div>

      <!-- COLUMN -->
      <div :style="{ width: S.WIDTH_LAYOUT_MIDDLE }">
        <div
          v-for="(item, index) in dataItem"
          :key="index"
          class="bg-white transition-box-shadow"
          :class="{'shadow-item-section': !isDisabled(item)}"
          :style="{ zIndex }"
        >
          <div
            class="is-flex column-body-wrapper"
            :style="{ height: `${S.HEIGHT_INIT_COLUMN + 10}px` }"
          >
            <!-- <div :style="{ width: `${S.WIDTH_KETERANGAN}px` }">
              <div v-if="item.note" class="is-relative">
                <div class="item-note-wrapper" :style="{ height: `${S.HEIGHT_INIT_COLUMN + 3}px` }">
                  <p class="white-space-preline">{{ item.note }}</p>
                </div>
                <div
                  v-if="!readOnly"
                  class="is-clickable edit-note-icon"
                  @click="() => handleEditNote(item, index)"
                >
                  <EditIcon />
                </div>
              </div>
              <div
                v-else
                class="flex-center c-w-100 c-h-100"
                :class="[!readOnly ? 'is-clickable' : '']"
                @click="() => handleEditNote(item, index)"
              >
                <p class="mr-2" :class="[!readOnly ? 'averta-bold color-red' : 'averta-reguler-italic color-grey']">Belum diatur</p>
                <EditIcon v-if="!readOnly" />
              </div>
            </div> -->

            <!-- :style="{ width: `${S.WIDTH_HARGA}px` }" -->
            <div
              :style="{ width: S.WIDTH_PERCENT_HARGA }"
              class="flex-center-vertical is-justify-content-space-between averta-bold"
            >
              <p class="mr-1">Rp</p>
              <InputPrice
                :hideFormat="true"
                :hideBorder="true"
                :value="item.price || 0"
                :disabled="isDisabled(item)"
                :className="isDisabled(item) ? 'averta-bold' : 'hover-border-1-grey-2'"
                @change="handleChangeInput($event, 'price', item)"
              />
              <!-- <p>Rp</p>
              <p>{{ formatThousand(item.price) }}</p> -->
            </div>
            <!-- <div :style="{ width: `${S.WIDTH_VOL}px` }"> -->
            <div class="flex-center" :style="{ width: S.WIDTH_PERCENT_VOL }">
              <!-- <InputPrice
                :hideFormat="true"
                :hideBorder="true"
                :value="item.volume"
                :disabled="isDisabled(item)"
                :className="isDisabled(item) ? 'averta-bold has-text-centered"
                @change="handleChangeInput($event, 'volume', item)"
              /> -->

                <!-- v-model="item.volume" -->
              <input
                v-model="item.volume"
                type="number"
                class="averta-bold has-text-centered outline-none c-w-100 input-volume-unit-price"
                :disabled="isDisabled(item)"
                placeholder="0"
                :class="[{'hover': !isDisabled(item)}]"
                @input="(e) => handleChangeInput(e.target.value, 'volume', item)"
              >
              <!-- <p class="has-text-centered">{{ item.volume }}</p> -->
            </div>
            <!-- <div :style="{ width: `${S.WIDTH_SAT}px` }"> -->
            <div class="flex-center p-0" :style="{ width: S.WIDTH_PERCENT_SAT }">
              <InputAutocompleteSatuan
                :value="{ name: item.unit_name, id: item.unit_id }"
                :hideBorder="true"
                :unsetMaxWidth="true"
                :bold="true"
                :disabled="isDisabled(item)"
                :idAutocomplete="`${index}-${code}`"
                @selectedUnit="handleSelectUnit($event, item)"
              />
              <!-- <p class="has-text-centered">{{ item.unit_name }}</p> -->
            </div>
            <!-- :style="{ width: `${S.WIDTH_MATERIAL}px` }" -->
            <div
              class="flex-center-vertical"
              :style="{ width: S.WIDTH_PERCENT_MATERIAL }"
              :class="[isWorkerFees ? 'bg-lightgray-8' : '']"
            >
              <div
                v-if="!isWorkerFees && (item.material_price || item.price)"
                class="flex-center-vertical c-w-100 is-justify-content-space-between averta-bold"
              >
                <!-- <p class="mr-1">Rp</p>
                <InputPrice
                  :hideFormat="true"
                  :hideBorder="true"
                  :value="item.material_price"
                  :disabled="isDisabled(item)"
                  :className="isDisabled(item) ? 'averta-bold' : 'hover-border-1-grey-2'"
                  @change="item.material_price = $event"
                /> -->
                <p>Rp</p>
                <p>{{ formatThousand(item.material_price ? item.material_price : ((item.volume || 0) * item.price)) }}</p>
              </div>
            </div>
            <!-- :style="{ width: `${S.WIDTH_UPAH}px` }" -->
            <div
              class="flex-center-vertical"
              :style="{ width: S.WIDTH_PERCENT_UPAH }"
              :class="[!isWorkerFees ? 'bg-lightgray-8' : '']"
            >
              <div
                v-if="isWorkerFees && item.worker_price"
                class="flex-center-vertical c-w-100 is-justify-content-space-between averta-bold"
              >
                <!-- <p class="mr-1">Rp</p>
                <InputPrice
                  :value="item.worker_price || 0"
                  :hideFormat="true"
                  :hideBorder="true"
                  :disabled="isDisabled(item)"
                  :className="isDisabled(item) ? 'averta-bold' : 'hover-border-1-grey-2'"
                  @change="item.worker_price = $event"
                /> -->
                <p>Rp</p>
                <p>{{ formatThousand(item.worker_price) }}</p>
              </div>
            </div>
            <!-- TOTAL PRICE -->
            <!-- <div :style="{ width: S.WIDTH_PERCENT_TOTAL_PRICE }">
              <div v-if="item.material_price || item.worker_price" class="averta-bold flex-center-vertical is-justify-content-space-between c-h-100">
                <p>Rp</p>
                <p>{{ !isWorkerFees ? formatThousand(item.material_price) : formatThousand(item.worker_price) }}</p>
              </div>
            </div> -->
          </div>
        </div>
      </div>

      <!-- ACTION -->
      <div class="sticky-right bg-lightgray-8" :style="{ width: S.WIDTH_LAYOUT_RIGHT, zIndex: 12 }">
        <!-- width: `${S.WIDTH_STICKY_TITLE_RIGHT}px`, -->
        <div
          v-for="(item, index) in dataItem"
          :key="index"
          class="column-body right-body flex-center"
          :class="{'shadow-item-section': !isDisabled(item)}"
          :style="{
            width: '100%',
            height: `${S.HEIGHT_INIT_COLUMN + 10}px`,
            zIndex
          }"
        >
          <div v-if="!readOnly">
            <div v-if="isDisabled(item)" class="flex-center">
              <div class="is-clickable mr-2" @click="() => handleEdit(item, index)">
                <EditIcon color="black" size="18" />
              </div>
              <div class="is-clickable" @click="() => handleDelete(item)">
                <TrashIcon size="18" />
              </div>
            </div>
            <div v-else class="flex-center">
              <img
                class="mr-2 is-clickable"
                :src="require('@/assets/icons/circle-check-red-used.svg')"
                alt=""
                @click="() => handleConfirmEdit(item)"
              >
              <img
                class="is-clickable"
                :src="require('@/assets/icons/circle-cross-black-used.svg')"
                alt=""
                @click="() => handleCancelEdit(item)"
              >
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="!readOnly"
      class="add-item-section sticky-left"
      :style="{
        width: '100%',
        minWidth: `${S.WIDTH_TABLE}px`,
        height: `${S.HEIGHT_STICKY_TITLE_SECTION}.1px`
      }"
    >
      <p class="averta-bold color-red title-section is-clickable" @click="handleAdd">
        + Tambah Data
      </p>
    </div>
  </div>
</template>

<script>
import { formatCurrency, removeComma, arrayToName, toastMessage } from '@/helpers'
import { mapGetters } from 'vuex'
import { EditIcon, TrashIcon } from '@/components/icons'

export default {
  name: 'ItemSection',
  components: {
    EditIcon,
    TrashIcon,
    InputPrice: () => import('@/components/input/InputPrice.vue'),
    InputAutocompleteSatuan: () => import('@/components/input/InputAutocompleteSatuan.vue'),
    InputAutocompleteMaterials: () => import('@/components/input/InputAutocompleteMaterials.vue'),
    InputAutocompleteUpah: () => import('@/components/input/InputAutocompleteUpah.vue')
  },
  props: {
    dataProps: {
      type: Array,
      default: () => [],
      required: true
    },
    title: {
      type: String,
      required: true
    },
    background: {
      type: String,
      required: true
    },
    code: {
      type: String
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    zIndex: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      dataItem: [],
      isLoading: false,
      materialName: '',
      selectedItem: []
    }
  },
  computed: {
    ...mapGetters({
      url: 'setup/getUrl',
      S: 'unitPrice/staticUnitPrice',
      formDetail: 'unitPrice/getFormDetailUnitPrice'
    }),
    isMaterial () {
      return this.code === 'materials'
    },
    isWorkTool () {
      return this.code === 'work_tools'
    },
    isAuxiliaryTool () {
      return this.code === 'auxiliary_tools'
    },
    isWorkerFees () {
      return this.code === 'worker_fees'
    }
  },
  methods: {
    formatThousand (val) {
      if (!val) return '0'
      return formatCurrency(val, true)
    },
    handleAdd () {
      this.$emit('addItem')
      this.selectedItem = JSON.parse(JSON.stringify(this.dataProps.filter(item => item.isAdd)))
    },
    handleEditNote (data, index) {
      this.$emit('editDescription', { ...data, index })
    },
    handleEdit (data, index) {
      // const temp = JSON.parse(JSON.stringify({ ...data, index }))
      // this.selectedItem.push(temp)
      this.$emit('editItem', { ...data, index }, `edit_${this.code}`)
    },
    handleDelete (data) {
      this.selectedItem.splice(this.selectedItem.findIndex(item => item.index === data.index), 1)
      this.dataItem = this.dataItem.filter((item) => item.id ? item.id !== data.id : item.index !== data.index)
      this.$emit('removeItem', this.dataItem)
    },
    handleCancelEdit (item) {
      const findIndex = this.selectedItem.findIndex(data => data.index === item.index)
      if (findIndex !== -1 && this.selectedItem[findIndex].isAdd) {
        this.handleDelete(item)
      } else {
        this.$emit('editItem', this.selectedItem[findIndex])
        this.selectedItem.splice(this.selectedItem.findIndex(data => data.index === item.index), 1)
      }
    },
    handleSelectUnit ($event, item) {
      item.unit_name = $event.name
      item.unit_id = $event.id
    },
    handleSelectUpah ($event, item) {
      item.name = $event.name
      item.fee_type = $event.fee_type
    },
    handleSelectMaterial ($event, item) {
      if ($event && $event.length > 0) {
        item.name = arrayToName($event)
        item.selected = $event
      } else {
        item.name = null
        item.selected = []
      }
    },
    handleConfirmEdit (item) {
      if ((this.isWorkerFees ? !item.fee_type : !item.name) || !item.volume || !item.price || !item.unit_id) return toastMessage('Mohon Lengkapi Form', 'is-dark')
      const findIndex = this.selectedItem.findIndex(data => data.index === item.index)
      delete item.isAdd
      delete this.selectedItem[findIndex].isAdd

      this.$emit('editItem', { ...item, code: `edit_${this.code}` })
      this.selectedItem.splice(this.selectedItem.findIndex(data => data.index === item.index), 1)

      this.handleStoreItem(item)
    },
    handleStoreItem (data) {
      const body = {
        ...this.formDetail,
        index: this.formDetail ? this.formDetail.index : null,
        volume: data.volume,
        price: data.price,
        unit_id: data.unit_id,
        unit_name: data.unit_name,
        code: data.code,
        isEdit: true,
        note: ''
      }

      if (this.isWorkerFees) {
        body.worker_price = data.volume * removeComma(data.price)
      } else {
        body.material_price = data.volume * removeComma(data.price)
      }

      let payload = null
      if (this.isMaterial) {
        payload = {
          ...body,
          name: data.name
        }
      } else if (this.isWorkTool) {
        payload = {
          ...body,
          name: data.name
        }
      } else if (this.isAuxiliaryTool) {
        payload = {
          ...body,
          name: data.name
        }
      } else if (this.isWorkerFees) {
        payload = {
          ...body,
          name: data.fee_type === 1 || data.fee_type === '1' ? 'Upah Tukang' : 'Upah Kenek',
          fee_type: data.fee_type,
          isEdit: data.code === 'edit_worker_fees'
        }
      }
      this.$store.commit('unitPrice/setDetailUnitPrice', payload)
    },
    handleChangeInput (value, type, item) {
      if (type === 'price') {
        const temp = value.toString().replace(/\D/g, '')
        item.price = temp ? formatCurrency(temp, true) : 0
      }

      const volume = type === 'volume' ? JSON.parse(JSON.stringify(value)) : item.volume || 0
      if (this.isWorkerFees) {
        item.worker_price = (volume) * removeComma(item.price || 0)
      } else {
        item.material_price = (volume) * removeComma(item.price || 0)
      }
      item.total_price = (volume) * removeComma(item.price || 0)
    },
    isDisabled (item) {
      const temp = JSON.parse(JSON.stringify(item))
      return !this.selectedItem.some(data => data.id === temp.id || data.index === temp.index)
    }
  },
  mounted () {
    this.dataItem = this.dataProps
    this.dataItem.forEach((item, index) => {
      item.index = index
    })
    if (this.code === 'materials' && this.dataProps && this.dataProps.length > 0 && this.dataProps[0].name && this.dataProps[0].name !== 'empty') {
      this.materialName = this.dataProps[0].name
    }
  },
  watch: {
    dataProps: {
      handler (val) {
        this.dataItem = val
        this.dataItem.forEach((item, index) => {
          item.index = index
        })
      },
      deep: true
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/scss/_variables.scss';

.transition-box-shadow {
  transition: box-shadow 0.3s ease-in-out;
}

.column-body {
  padding: 0 10px;
  transition: box-shadow 0.3s ease-in-out;
  background: white;

  &.left-body {
    z-index: 4;
    display: flex;
    align-items: center;
    border-right: 6px solid $color-grey-13;
    border-bottom: 1px solid $color-grey-13;
  }

  &.right-body {
    border-left: 4px solid $color-grey-13;
    border-bottom: 1px solid $color-grey-13;
    z-index: 4;
  }
}

.title-section {
  position: sticky;
  left: 12px;
}

.column-body-wrapper {
  > div {
    background: white;
    padding: 10px;
    border-bottom: 1px solid $color-grey-13;
    border-left: 1px solid $color-grey-13;
  }
}

.add-item-section {
  display: flex;
  align-items: center;
  border-top: 1px dashed $color-red-1;
  background: #fafafa;
  z-index: 1;
}

.edit-note-icon {
  position: absolute;
  right: 0px;
  top: 8px;
}

.item-note-wrapper {
  width: 92%;
  margin-top: -7px;
  overflow: auto;
}

.wrap-body-modal-materials {
  background: white;
  height: 200px;
  width: 100%;
  overflow: hidden;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  overflow-y: auto;
  overflow-x: hidden;
  position: absolute;
  top: 50px;
  z-index: 5;
}

.data-item-wrapper {
  height: 100%;

  > div {
    &:hover {
      background: #FFE5E5;
    }

    .input {
      width: 20px;
      height: 20px;
    }
  }

  .img-item-material {
    width: 40px;
    height: 40px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.input-volume-unit-price {
  border: none;
  padding: 8px 0;
}

.input-volume-unit-price:disabled{
  background: white;
}

.input-volume-unit-price.hover:hover, .input-volume-unit-price.hover:focus {
  border: 1px solid $color-grey-2;
}

.shadow-item-section {
  box-shadow: 0px 10px 40px 0px #2525253F;
  position: relative;
}
</style>
